import { EventBus } from 'event-bus-vfs/index';
import React, { ReactNode, useEffect, useState } from 'react';
import { useViewport } from '../react-viewport-provider';
import { Modal } from 'rsuite';
import 'rsuite/dist/styles/rsuite-default.css';
import { MODAL_OPEN, MODAL_CLOSE } from './events';

export interface ModalProps {
  show: boolean;
  modalTitle: ReactNode;
  bodyChildren?: ReactNode;
  backdrop: boolean | 'static';
  footerChildren?: ReactNode;
  size?: 'xs' | 'sm' | 'md' | 'lg';
  full?: boolean;
  overFlow: boolean;
  closeButton: boolean;
  onHide?(): void;
  autoFocus?: boolean;
  containerStyle: object | undefined;

  modalTitleStyle: object | undefined;
}

export const ModalV = (props: ModalProps) => {
  const { bp } = useViewport();
  const initialValues: ModalProps = {
    show: false,
    modalTitle: '',
    bodyChildren: null,
    backdrop: true,
    footerChildren: null,
    size: 'md',
    full: false,
    overFlow: true,
    closeButton: true,
    autoFocus: false,
    containerStyle: undefined,
    modalTitleStyle: undefined,
  };
  const [modalObject, setModalObject] = useState(initialValues);

  useEffect(() => {
    function _onModalOpen(modalVProps: ModalProps): void {
      const {
        show,
        modalTitle,
        bodyChildren,
        backdrop,
        footerChildren,
        size,
        full,
        overFlow,
        closeButton,
        autoFocus,
        containerStyle,
        modalTitleStyle,
      } = modalVProps;
      const defaultValues: ModalProps = {
        show,
        modalTitle,
        bodyChildren,
        backdrop,
        footerChildren,
        size,
        full,
        overFlow,
        closeButton,
        autoFocus,
        containerStyle,

        modalTitleStyle,
      };
      setModalObject(defaultValues);
    }

    function _onModalClose(modalVProps: ModalProps): void {
      const {
        show,
        modalTitle,
        bodyChildren,
        backdrop,
        footerChildren,
        size,
        full,
        overFlow,
        closeButton,
        autoFocus,
        containerStyle,
        modalTitleStyle,
      } = modalVProps;
      const defaultValues: ModalProps = {
        show,
        modalTitle,
        bodyChildren,
        backdrop,
        footerChildren,
        size,
        full,
        overFlow,
        closeButton,
        autoFocus,
        containerStyle,

        modalTitleStyle,
      };
      setModalObject(defaultValues);
    }

    const modalEvents = EventBus.on(MODAL_OPEN, _onModalOpen);
    const modalCloseEvent = EventBus.on(MODAL_CLOSE, _onModalClose);

    return function cleanup() {
      modalEvents.off();
      modalCloseEvent.off();
    };
  }, []);

  const handleClose = () => {
    setModalObject({
      show: false,
      modalTitle: '',
      bodyChildren: null,
      backdrop: true,
      footerChildren: null,
      size: 'md',
      full: false,
      overFlow: true,
      closeButton: true,
      autoFocus: false,
      containerStyle: undefined,

      modalTitleStyle: undefined,
    });
  };

  return (
    <div className="modal-container">
      <Modal
        backdrop={modalObject.backdrop}
        show={modalObject.show}
        onHide={props?.onHide ?? handleClose}
        size={modalObject.size}
        full={modalObject.full}
        style={modalObject.containerStyle}
      >
        <Modal.Header>
          <Modal.Title styel={modalObject.modalTitleStyle}>
            {modalObject.modalTitle}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body">
          {modalObject.bodyChildren}
        </Modal.Body>
        <Modal.Footer>{modalObject.footerChildren}</Modal.Footer>
      </Modal>
    </div>
  );
};
