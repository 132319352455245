// @ts-nocheck
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, Redirect } from 'react-router-dom';

import { LoaderV } from '../widgets/LoaderV';


import config from '../config/default.config.json';

const homeURL = config?.homepageURL;


const HomeScreen = lazy(() => import('../screens/Home'));
const DocScreen = lazy(() => import('../screens/Doc'));


const RouterConfig = props => (
  <Switch>
    <Route path="/docs" component={DocScreen} />
    <Route path="/" component={HomeScreen} />
  </Switch>
);

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: `${homeURL}`,
          }}
        />
      )
    }
  />
);

function RouteWithSubRoutes(route) {
  return (
    <Route
      path={route.path}
      render={props => (
        // pass the sub-routes down to keep nesting
        <route.component {...props} routes={route.routes} />
      )}
    />
  );
}

const Stack = createStackNavigator();

const AppNavigatorInternal = (): JSX.Element => {
  return (
    <NavigationContainer>
      <Stack.Navigator headerMode={'none'}>
        {/* <Stack.Screen name="VerifierScreen" component={VerifierScreen} /> */}
      </Stack.Navigator>
    </NavigationContainer>
  );
}

export const AppNavigator = (): JSX.Element => {
  return (
    <Router>
      <Suspense fallback={<LoaderV loadingText={'loading'} />}>
        <RouterConfig />
      </Suspense>
    </Router>
  );
};
